import { Pages } from "../enums";
import { useAuth } from "./useAuth";


interface PrivateOptionsProps {
  pageSetter: (content: Pages) => void;
}

type PrivatePageOpenProps = {
  authorized: boolean
  setAuthorized: (authorized: boolean) => void
}

function usePrivateOptions (props: PrivateOptionsProps)  {

  const { checkAuth } = useAuth()

  const onPublicPageOpen = ():void => {
    props.pageSetter(Pages.PUBLIC_PAGE)
  }

  const onPrivatePageOpen = ({ setAuthorized, authorized }: PrivatePageOpenProps):void => {

    props.pageSetter(Pages.LOAD_PAGE)

    checkAuth().then((val) => {
      if (val) {
        setAuthorized(true)
        props.pageSetter(Pages.PRIVATE_PAGE)
      } else {
        setAuthorized(false)
        props.pageSetter(Pages.AUTH_PAGE)
      }
    })
  }


  return { onPrivatePageOpen, onPublicPageOpen } as const; 
}


export default usePrivateOptions