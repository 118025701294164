import React, { ReactNode, useEffect, useState } from 'react'
import SignInInput from './signInInput/signInInput'
import { Status } from '../../enums'
import { HashLoader } from 'react-spinners'

type Form = {
  onSubmit: Function
}


function SignInForm({ onSubmit }: Form) {

  const [code, setCode] = useState<number | null>(null)
  const [key, setKey] = useState<string | null >(null)
  const [formState, setFormState] = useState<Status>(Status.DEFAULT)
  const [btn, setBtn] = useState<ReactNode>(<></>)

  const onChangeCode = (e: any) => {
    setCode(e.target.value)
  }

  const onChangeKey = (e: any) => {
    setKey(e.target.value)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setFormState(Status.PENDING)
    const res = await onSubmit(key, code)
    res.status === Status.ERROR ? setFormState(Status.ERROR) : setFormState(Status.DEFAULT)
  }

  useEffect(() => {
    switch (formState) {
      case Status.DEFAULT:
        setBtn(
          <button className='w-[400px] transition-[0.25s] rounded-[10px] h-[60px] hover:bg-[var(--second-background)] bg-[var(--main-accented)]'>
            <h1 className='text-[var(--main-text)] transition-[0.25s] hover:text-[var(--main-text-accented)]'>get access</h1>
          </button>
        )
        break;
      
      case Status.PENDING:
        setBtn (
          <button className='w-[400px] transition-[0.25s] rounded-[10px] h-[60px] hover:bg-[var(--second-background)] bg-[var(--main-accented)] flex items-center justify-center'>
            <HashLoader color='#868686' size={25} />
          </button>
        )
        break;

      case Status.ERROR:
        setBtn (
          <button className='w-[400px] transition-[0.25s] rounded-[10px] h-[60px] hover:bg-[var(--second-background)] bg-[var(--main-accented)]'>
            <h1 className='text-[var(--main-text)] transition-[0.25s] hover:text-[var(--main-text-accented)]'>re try</h1>
          </button>
        )
        break;
    
      default:
        <button className='w-[400px] transition-[0.25s] rounded-[10px] h-[60px] hover:bg-[var(--second-background)] bg-[var(--main-accented)]'>
          <h1 className='text-[var(--main-text)] transition-[0.25s] hover:text-[var(--main-text-accented)]'>get access</h1>
        </button>
        break;
    }
  }, [formState])

  return (
    <div className='w-[1200px] h-[400px] text-[var(--main-text-accented)] flex items-center justify-center flex-col'>
      <div className='w-full h-[100px] flex items-center justify-center'>
        <h1 className='text-[45px] font-extrabold'>Authorization</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='w-full h-[200px] flex flex-col items-center justify-center gap-x-[100px]'>
          <SignInInput onChange={onChangeCode} val={code} width={'200'} text={'code'} type={'number'} name={'code'}/>
          <SignInInput onChange={onChangeKey} val={key} width={'500'} text={'key'} type={'password'} name={'password'}/>
        </div>
        <div className='w-full h-[100px] flex items-center justify-center'>
          {btn}
        </div>
      </form>
    </div>
  )
}

export default SignInForm