export const enum Status {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'default'
}

export const enum AuthStatuses {
  AUHORIZED = 'authorized', 
  NONATHORIZED = 'nonAuthorized', 
  CHECKING = 'checking',
}

export const enum Pages {
  PRIVATE_PAGE = 'private',
  PUBLIC_PAGE = 'public',
  LOAD_PAGE = 'load',
  AUTH_PAGE = 'auth',
}