import React, { ReactNode, useEffect, useState } from "react";
import { Pages } from "../enums";
import usePrivateOptions from "../hooks/usePrivateOptions";

export type AuthContextType = {
  authorized: boolean,
  setAuthorized: (authorized: boolean) => void;
  onPageOpen: (e:any ) => void
}

type AuthPageType = {
  children: ReactNode,
}

export type SetPageType = {
  page: Pages | null,
  pageSetter: (content: Pages) => void;
}

export const AuthContext = React.createContext<AuthContextType & SetPageType | null>(null)



const AuthProvider = ({ children, pageSetter, page }: AuthPageType & SetPageType): JSX.Element => {

  const [authorized, setAuthorized] = useState<boolean>(false)

  const { onPublicPageOpen, onPrivatePageOpen } = usePrivateOptions({pageSetter})

  const getPage = (): void => {
    const location = window.location.search
    const currentPage = new URLSearchParams(location).get('page')

    switch (currentPage) {
      case Pages.PRIVATE_PAGE:
        onPrivatePageOpen({ setAuthorized, authorized })
        break;

      case Pages.PUBLIC_PAGE:
        onPublicPageOpen()
        break;
    
      default:
        onPublicPageOpen()
        break; 
    }
  }

  useEffect(() => {
    getPage()
  }, [])

  const onPageOpen = (page: Pages): void => {

    if (authorized) {
      switch (page) {
        case Pages.PRIVATE_PAGE:
          pageSetter(Pages.PRIVATE_PAGE)
          break;
        case Pages.PUBLIC_PAGE:
          pageSetter(Pages.PUBLIC_PAGE)
          break;
      
        default:
          break;
      }
    } else {
      switch (page) {
        case Pages.PRIVATE_PAGE:
          onPrivatePageOpen({ setAuthorized, authorized })
          break;
        case Pages.PUBLIC_PAGE:
          onPublicPageOpen()
          break;
      
        default:
          break;
      }
    }
  }

  return (
    <AuthContext.Provider value={{authorized, setAuthorized, page, pageSetter, onPageOpen}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider