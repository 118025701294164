import Page from "../../components/page/page";

function PublicPage() {
  return (
    <Page>
      <div className='w-full h-full flex items-center justify-center'>
        <h1 className='text-[30px] font-semibold text-[var(--second-text)]'>later on this page u can see a public files which i sharing</h1>
      </div>
    </Page>
  )
}

export default PublicPage