import AppWrapper from './components/appWrapper/appWrapper';
import PageWrapper from './components/pageWrapper/pageWrapper';
import { Pages } from './enums'
import { ReactNode, useEffect, useState } from 'react';
import PublicPage from './pages/publicPage/publicPage';
import usePrivateOptions from './hooks/usePrivateOptions';
import PrivatePage from './pages/privatePage/privatePage';
import LoadingPage from './pages/loadingPage/loadingPage';
import AuthPage from './pages/authPage/authPage';
import AuthProvider from './contexts/authContext';


function App() {


  const [page, setPage] = useState<Pages | null >(null)
  const [reactNodePage, setReactNodePage] = useState<ReactNode>(<></>)
  
  const changePage = (newContent: Pages) => {
    setPage(newContent);
  };

  useEffect(() => {
    switch (page) {
      case Pages.PUBLIC_PAGE:
        setReactNodePage(<PublicPage/>)
        break;

      case Pages.PRIVATE_PAGE:
        setReactNodePage(<PrivatePage/>)
        break;
      
      case Pages.LOAD_PAGE:
        setReactNodePage(<LoadingPage/>)
        break;

      case Pages.AUTH_PAGE:
        setReactNodePage(<AuthPage/>)
        break;
    
      default:
        setReactNodePage(<LoadingPage/>)
        break;
    }
  }, [page])

  return (
    <AppWrapper>
      <AuthProvider page={page} pageSetter={changePage}>
        <PageWrapper currentPage={page}>
          {reactNodePage}
        </PageWrapper>
      </AuthProvider>
    </AppWrapper>
  );
}

export default App;
