import React  from 'react'
import './signInInput.css'

type SignInInputProps = {
  width: string,
  val: number | string | null,
  onChange: any,
  text: string,
  type: string,
  name: string,
}

const SignInInput: React.FC<SignInInputProps> = ({ onChange, val, width, text, type, name }) => (
  <div className='input-wrapper'>
    <div className='input-box'>
      <input onChange={onChange} className='login' name={name} type={type} placeholder='' required={true}></input>
      <span>{text}</span>
    </div>
  </div>
);

export default SignInInput;