import React, { ReactNode } from 'react'

type PageProps = {
  children: ReactNode,
}

const Page: React.FC<PageProps> = ({ children }) => (
  <div className='w-full h-full bg-[var(--main-backgoround)] overflow-hidden'>
    {children}
  </div>
);

export default Page;