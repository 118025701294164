import React, { useContext } from "react";
import Page from "../../components/page/page";
import { AuthContext, AuthContextType, SetPageType } from "../../contexts/authContext";
import { useAuth } from "../../hooks/useAuth";
import { Pages, Status } from "../../enums";
import SignInForm from "../../components/signInForm/signInForm";

function AuthPage() {

  const { setAuthorized, pageSetter } = React.useContext(AuthContext) as AuthContextType & SetPageType

  const { logIn } = useAuth()


  const handleAuth = async (key: string, code: number): Promise<{status: Status}> =>  {

    let res = {status: Status.PENDING}

    await logIn(key, code).then((val) => {
      if (val.status === Status.SUCCESS) {
        setAuthorized(true)
        pageSetter(Pages.PRIVATE_PAGE)
        res.status = Status.SUCCESS
      } else {
        res.status = Status.ERROR
      }
    })

    return res

  }

  
  return (
    <Page>
      <div className='w-full h-full flex items-center justify-center flex-col'>
        <SignInForm onSubmit={handleAuth}/>
      </div>
    </Page>
  )
}

export default AuthPage