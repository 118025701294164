import React, { ReactNode } from 'react'

type AppProps = {
  children: ReactNode,
}

const AppWrapper: React.FC<AppProps> = ({ children }) => (
  <div className='w-full min-w-[1200px] h-[100vh] overflow-hidden'>
    {children}
  </div>
);

export default AppWrapper;