import React from 'react'
import { AuthContext, AuthContextType, SetPageType } from '../../contexts/authContext'

type NavBarBtnsProps = {
  text: string,
  selected: boolean,
}

function NavBarBtn ({text, selected}: NavBarBtnsProps): JSX.Element {

  const { onPageOpen } = React.useContext(AuthContext) as AuthContextType & SetPageType


  const handleChangePage = () => {
    onPageOpen(text)
  }

  return (
    <div onClick={handleChangePage} className={`w-[100px] h-full flex items-center justify-center cursor-pointer ${selected? 'opacity-100' : 'opacity-50'}`}>
      <h1 className='text-[14px] font-light text-text_primary'>{text}</h1>
    </div>
  )
}

export default NavBarBtn


