import React, { ReactNode } from 'react'
import NavBar from '../navBard/navBar'
import { Pages } from '../../enums';

type PageWrapperProps = {
  children: ReactNode,
  currentPage: Pages | null,
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children, currentPage }) => (
  <div className='w-full h-full bg-slate-900 overflow-hidden'>
    <NavBar currentPage={currentPage} />
    <div className='w-full h-[calc(100vh-80px)]'>
      {children}
    </div>
  </div>
);

export default PageWrapper;