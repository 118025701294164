import React, { useEffect, useState } from "react";
import { AuthStatuses, Status } from '../enums'
import axios from "axios";
import { AuthContext, AuthContextType, SetPageType } from "../contexts/authContext";


const API_URL = process.env.REACT_APP_API_URL


export function useAuth() {

  type AuthResponse = {
    status: Status,
    message: string,
  }

  const checkAccess = async (accessToken: string): Promise<boolean> => {

    let res = false

    try {
      const response = await axios.post(`${API_URL}author/checkAccess`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      
      response.data.status === Status.SUCCESS ? res = true : res = false
      
    } catch (error) {
      console.error('access error', error);
    }

    return res
  }

  const checkAuth =  async (): Promise<boolean> => {

    const accessToken = localStorage.getItem('access_token')
    if (accessToken === null) return false

    const res = await checkAccess(accessToken)

    return res
  } 

  const logIn = async (key: string, code: number): Promise<AuthResponse> => {

    let res = {status: Status.PENDING, message: 'get auth state'}

    const response = await axios.post(`${API_URL}author/logIn`, {code: code, key: key})

    const access_token = response.data.token

    await checkAccess(access_token).then((val) => {
      if (val) {
        localStorage.setItem('access_token', access_token)
        res.status = Status.SUCCESS
        res.message = 'you has been authorized'
      } else {
        res.status = Status.ERROR
        res.message = 'Invalid token'
      }
    })

    return res
  }

  return { checkAuth, logIn } as const; 
}