import React from 'react'
import NavBarBtn from '../navBarBtn/navBarBtn';
import { Pages } from '../../enums';


type NavBarProps = {
  currentPage: Pages | null
}


const NavBar: React.FC<NavBarProps> = ({ currentPage }) => (
  <div className='w-[100%] h-[80px] py-[20px] bg-red-500 bg-bg_secondary flex flex-row items-center text-text_primary'>
    <div className='h-full w-[250px] flex items-center justify-start px-[20px]'>
      <h1 className='text-[14px] font-extrabold'>OGNEV'S FILE API</h1>
    </div>
    <div className='w-[calc(100%-250px)] h-full flex flex-row items-center justify-end gap-x-[50px]'>
      <NavBarBtn text='private' selected={currentPage === Pages.PRIVATE_PAGE ? true : false}/>
      <NavBarBtn text='public' selected={currentPage === Pages.PUBLIC_PAGE ? true : false}/>
    </div>
  </div>
);

export default NavBar;